import React from 'react';
import ResellersProductGrid from '../components/ResellersProductGrid';
import './ResellersPage.css';

const ResellersPage = () => {
  // Products data
  const resellersProducts = [
    { name: 'Bamboo Kit 1', image: 'aya_website_data/homepage_pics/bamboo_kit_1.jpg', id: 'bambookit-1-resellers' },
    { name: 'Bamboo Kit 2', image: 'aya_website_data/homepage_pics/bamboo_kit_2.jpg', id: 'bambookit-2-resellers' },
    { name: 'Eco Kit', image: 'aya_website_data/homepage_pics/eco_kit.png', id: 'ecokit-resellers' },
    { name: 'Bazooka Kit 3', image: 'aya_website_data/homepage_pics/bazooka_kit_3.jpg', id: 'bazooka-kit-3-resellers' },
    { name: 'Sushi Maker 3', image: 'aya_website_data/homepage_pics/sushi_maker_3.jpg', id: 'sushimaker-3-resellers' },
    { name: 'Lover Set', image: 'aya_website_data/homepage_pics/lover_set.jpg', id: 'loverset-resellers' },
  ]
  
  // Handle product click
  const handleProductClick = (productId) => {    
      window.location.href = `/resellers/${productId}`;
  };
  
  return (
    <div className="resellers-page">
      <h1 className="resellers-page-title">RESELLERS</h1>
      <h1 className="resellers-page-subtitle">CLICK ON PRODUCT FOR MORE INFORMATION</h1>      
      <ResellersProductGrid
        products={resellersProducts.map(product => ({
          ...product,
          onClick: () => handleProductClick(product.id),
        }))}
      />      
    </div>
  );
}

export default ResellersPage;