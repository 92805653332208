import React from 'react';
import ProductCard from './ProductCard';
import './ResellersProductGrid.css';

const ResellersProductGrid = ({ products }) => {
  return (
    <div className="resellers-product-grid">
      <div className="product-grid">
        {products.map((product) => (
          <ProductCard
            key={product.name}
            image={product.image}
            name={product.name}
            onClick={product.onClick} // Pass the onClick handler
          />
        ))}
      </div>
    </div>
  );
};

export default ResellersProductGrid;