import React, { useState } from 'react';
import './EcoKitResellers.css';

const EcoKitResellers = () => {
  // List of image paths (1 through 8)
  const images = [
    '/aya_website_data/resellers_pics/eco_kit/eco_kit_1.jpg',
    '/aya_website_data/resellers_pics/eco_kit/eco_kit_2.jpg',
    '/aya_website_data/resellers_pics/eco_kit/eco_kit_3.jpg',
    '/aya_website_data/resellers_pics/eco_kit/eco_kit_4.jpg',
    '/aya_website_data/resellers_pics/eco_kit/eco_kit_5.jpg',
    '/aya_website_data/resellers_pics/eco_kit/eco_kit_6.jpg',
    '/aya_website_data/resellers_pics/eco_kit/eco_kit_7.jpg',
    '/aya_website_data/resellers_pics/eco_kit/eco_kit_8.jpg',
  ];

  // State for the lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const showPrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const showNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="eco-kit-resellers">
      {/* Title Section */}
      <h1 className="product-title">ECO KIT</h1>

      {/* Top image grid */}
      <div className="image-grid">
        {images.map((imgPath, i) => (
          <div key={i} className="image-card" onClick={() => openLightbox(i)}>
            <img src={imgPath} alt={`Eco Kit - ${i + 1}`} />
            <div className="image-overlay"></div> {/* Just an empty div for grey effect */}
          </div>
        ))}
      </div>

      {/* Lightbox / Fullscreen overlay */}
      {isLightboxOpen && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <button className="close-button" onClick={closeLightbox}>×</button>
          <button className="arrow-button left-arrow" onClick={showPrev}>‹</button>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <img src={images[currentIndex]} alt="Fullscreen Eco Kit" className="fullscreen-img" />
          </div>
          <button className="arrow-button right-arrow" onClick={showNext}>›</button>
        </div>
      )}

      {/* Bottom section with two cards */}
      <div className="bottom-section">
        <div className="feature-card">
          <h2>PRODUCT FEATURES</h2>
          <ul>
            <li>The AYA ECO SUSHI KIT is 100% eco-friendly.</li>
            <li>All packaging is 100% biodegradable.</li>
            <li>This product is made of 100% recyclable material.</li>
            <li>The AYA ECO SUSHI KIT includes 2 natural premium bamboo mats, rice spreader, spatula and 5 chopsticks.</li>
            <li>Protect the environment. Instant results. Make sushi with your favorite ingredients from the comfort of your home.</li>
          </ul>
        </div>

        <div className="feature-card">
            <h2>SHIPPING INFORMATION</h2>
            <div className="shipping-info">
                <p>
                <strong>SKU: </strong>EcoSushiKit<br/>                
                <br/>  {/* Creates an extra blank line */}
                <strong>CTN dimensions and weight:</strong><br/>
                <br/>
                30 sets per CTN<br/>
                <br/>
                MEAS in cm: 54 x 35 x 25.5<br/>
                MEAS in inches: 21.3 x 13.8 x 10<br/>
                <br/>
                N.W.: 12.9 KGs<br/>
                G.W.: 13.9 KGs<br/>
                <br/>
                N.W.: 28.4 LBs<br/>
                G.W.: 30.6 LBs
                </p>
            </div>
        </div>
      </div>

      {/* PDF Download */}
      <div className="pdf-download">
        <a
            href="/aya_website_data/resellers_pics/eco_kit/eco_kit_info.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pdf-link"
        >
            <img
            src="/aya_website_data/adobe_acrobat_logo.avif"
            alt="PDF Symbol"
            className="pdf-icon"
            />
            <span>Download Info Sheet</span>
        </a>
      </div>
    </div>
  );
};

export default EcoKitResellers;