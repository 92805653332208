import { React, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import productMedia from "../data/productMedia";
import VideoTutorials from "../components/VideoTutorials";
import PDFInstructions from "../components/PDFInstructions";
import "./ProductPage.css";
import { Link } from 'react-router-dom';

const ProductPage = () => {
  const { productId } = useParams();
  const productData = productMedia[productId];

  useEffect(() => {
    // Scroll to section if hash present
    const hash = window.location.hash.slice(1);
    if (hash) {
      const section = document.getElementById(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }

    // Store the last instructions page (this product) in sessionStorage
    sessionStorage.setItem('lastInstructionsPage', productId);
  }, [productId]);

  if (!productData) {
    // If product not found, go home
    return <Navigate to="/" />;
  }

  // Determine if user has talked to Aya before
  const hasTalkedToAya = sessionStorage.getItem('hasTalkedToAya') === 'true';
  const chatbotText = hasTalkedToAya ? "Back to Aya" : "Talk to Aya";

  return (
    <div className="product-page">
      {/* Add the product title */}
      {/* <h1 className="product-page-title">{productData.title}</h1> */}

      {/* Add 'Buy Now' link for products that have it in their media data */}
      {productData.buy_link && (
        <div className="buy-link-container">
          <a 
            href={productData.buy_link}
            target="_blank"
            rel="noopener noreferrer"
            className="buy-link"
          >
            {/* <span>Buy Now:&nbsp;</span> */}
            <img
              src="/aya_website_data/new_amazon_logo.png"
              alt="Amazon logo"
              className="amazon-logo"
            />
          </a>
        </div>
      )}

      {/* Video Tutorials Section */}
      {productData.video_sections && productData.video_sections.length > 0 && (
        <section id="videos">
          <h1 className="video-tutorials-title">{productData.videos_section_title}</h1>
          {productData.video_sections.map((section, index) => (
            <VideoTutorials key={index} sectionTitle={section.title} videos={section.videos} />
          ))}
        </section>
      )}

      {/* PDF Instructions Section */}
      {productData.pdf_instructions && productData.pdf_instructions.length > 0 && (
        <section id="pdfs">
          <h1 className="instructions-title">{productData.instructions_section_title}</h1>
          <PDFInstructions pdfs={productData.pdf_instructions} />
        </section>
      )}

      {/* Chatbot Icon (Fixed at top-right corner) */}
      <Link to="/aya-assistant" className="chatbot-icon-link">
        <div className="chatbot-icon-box">
          <img 
            src="/aya_website_data/homepage_pics/aya_bot_img.png"
            alt="Chatbot"
            className="chatbot-icon-image"
          />
          <p className="chatbot-icon-text">{chatbotText}</p>
        </div>
      </Link>
    </div>
  );
};

export default ProductPage;