import React, { useState } from 'react';
import './BazookaKit3Resellers.css';

const BazookaKit3Resellers = () => {
  // List of image paths (1 through 8)
  const images = [
    '/aya_website_data/resellers_pics/bazooka_kit_3/bazooka_kit_3_1.jpg',
    '/aya_website_data/resellers_pics/bazooka_kit_3/bazooka_kit_3_2.jpg',
    '/aya_website_data/resellers_pics/bazooka_kit_3/bazooka_kit_3_3.jpg',
    '/aya_website_data/resellers_pics/bazooka_kit_3/bazooka_kit_3_4.jpg',
    '/aya_website_data/resellers_pics/bazooka_kit_3/bazooka_kit_3_5.jpg',
    '/aya_website_data/resellers_pics/bazooka_kit_3/bazooka_kit_3_6.jpg',
    '/aya_website_data/resellers_pics/bazooka_kit_3/bazooka_kit_3_7.jpg',
    '/aya_website_data/resellers_pics/bazooka_kit_3/bazooka_kit_3_8.jpg',
  ];

  // State for the lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const showPrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const showNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="bazooka-kit-3-resellers">
      {/* Title Section */}
      <h1 className="product-title">BAZOOKA KIT 3</h1>

      {/* Top image grid */}
      <div className="image-grid">
        {images.map((imgPath, i) => (
          <div key={i} className="image-card" onClick={() => openLightbox(i)}>
            <img src={imgPath} alt={`Bazooka Kit 3 - ${i + 1}`} />
            <div className="image-overlay"></div> {/* Just an empty div for grey effect */}
          </div>
        ))}
      </div>

      {/* Lightbox / Fullscreen overlay */}
      {isLightboxOpen && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <button className="close-button" onClick={closeLightbox}>×</button>
          <button className="arrow-button left-arrow" onClick={showPrev}>‹</button>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <img src={images[currentIndex]} alt="Fullscreen Bazooka Kit 3" className="fullscreen-img" />
          </div>
          <button className="arrow-button right-arrow" onClick={showNext}>›</button>
        </div>
      )}

      {/* Bottom section with two cards */}
      <div className="bottom-section">
        <div className="feature-card">
          <h2>PRODUCT FEATURES</h2>
          <ul>
            <li>Includes state-of-the-art AI assistant and exclusive step-by-step video tutorials by a professional sushi chef.</li>
            <li>Complete 19-piece set helps create sushi rolls and nigiri sushi in a dash.</li>
            <li>Includes chopsticks, saucers and mat made of premium bamboo.</li>
            <li>Made using dishwasher safe, BPA free, food grade plastic.</li>
            <li>Professional grade sushi knife and ceramic chopstick holders included.</li>
          </ul>
        </div>

        <div className="feature-card">
            <h2>SHIPPING INFORMATION</h2>
            <div className="shipping-info">
                <p>
                <strong>SKU: </strong>BO-PQD6-2IFZ<br/>                
                <br/>  {/* Creates an extra blank line */}
                <strong>CTN dimensions and weight:</strong><br/>
                <br/>
                20 sets per CTN<br/>
                <br/>
                MEAS in cm: 62 x 34 x 37<br/>
                MEAS in inches: 24.4 x 13.4 x 14.6<br/>
                <br/>
                N.W.: 16.3 KGs<br/>
                G.W.: 17.0 KGs<br/>
                <br/>
                N.W.: 35.9 LBs<br/>
                G.W.: 37.5 LBs
                </p>
            </div>
        </div>
      </div>

      {/* PDF Download */}
      <div className="pdf-download">
        <a
            href="/aya_website_data/resellers_pics/bazooka_kit_3/bazooka_kit_3_info.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pdf-link"
        >
            <img
            src="/aya_website_data/adobe_acrobat_logo.avif"
            alt="PDF Symbol"
            className="pdf-icon"
            />
            <span>Download Info Sheet</span>
        </a>
      </div>
    </div>
  );
};

export default BazookaKit3Resellers;