import React, { useState } from 'react';
import './BambooKit2Resellers.css';

const BambooKit2Resellers = () => {
  // List of image paths (1 through 8)
  const images = [
    '/aya_website_data/resellers_pics/bamboo_kit_2/bamboo_kit_2_1.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_2/bamboo_kit_2_2.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_2/bamboo_kit_2_3.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_2/bamboo_kit_2_4.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_2/bamboo_kit_2_5.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_2/bamboo_kit_2_6.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_2/bamboo_kit_2_7.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_2/bamboo_kit_2_8.jpg',
  ];

  // State for the lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const showPrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const showNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="bamboo-kit-2-resellers">
      {/* Title Section */}
      <h1 className="product-title">BAMBOO KIT 2</h1>

      {/* Top image grid */}
      <div className="image-grid">
        {images.map((imgPath, i) => (
          <div key={i} className="image-card" onClick={() => openLightbox(i)}>
            <img src={imgPath} alt={`Bamboo Kit 2 - ${i + 1}`} />
            <div className="image-overlay"></div> {/* Just an empty div for grey effect */}
          </div>
        ))}
      </div>

      {/* Lightbox / Fullscreen overlay */}
      {isLightboxOpen && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <button className="close-button" onClick={closeLightbox}>×</button>
          <button className="arrow-button left-arrow" onClick={showPrev}>‹</button>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <img src={images[currentIndex]} alt="Fullscreen Bamboo Kit" className="fullscreen-img" />
          </div>
          <button className="arrow-button right-arrow" onClick={showNext}>›</button>
        </div>
      )}

      {/* Bottom section with two cards */}
      <div className="bottom-section">
        <div className="feature-card">
          <h2>PRODUCT FEATURES</h2>
          <ul>
            <li>Includes state-of-the-art AI assistant and exclusive step-by-step video tutorials by a professional sushi chef.</li>
            <li>Tonight, You Home Sushi! Make sushi with your favorite ingredients and save your family a trip to the restaurant.</li>
            <li>Professional AYA GEN-3-upgraded sushi knife included.</li>
            <li>12-piece set includes: 2 natural premium bamboo mats, rice spreader, spatula, sushi knife, DIY nigiri maker, 5 pairs of fiberglass chopsticks, and storage bag.</li>
            <li>The AYA Bamboo Kit 2 includes upgraded chopsticks, knife and nigiri maker for the perfect gift.</li>
          </ul>
        </div>

        <div className="feature-card">
            <h2>SHIPPING INFORMATION</h2>
            <div className="shipping-info">
                <p>
                <strong>SKU:</strong><br/>
                SKU <strong>USA</strong>: XD-K4S7-ULI0<br/>
                SKU <strong>UK/EU</strong>: 64-JEXG-HJW8<br/>
                <br/>  {/* Creates an extra blank line */}
                <strong>CTN dimensions and weight:</strong><br/>
                <br/>
                20 sets per CTN<br/>
                <br/>
                MEAS in cm: 47.5 x 28.5 x 31<br/>
                MEAS in inches: 18.7 x 11.2 x 12<br/>
                <br/>
                N.W.: 11.0 KGs<br/>
                G.W.: 12.1 KGs<br/>
                <br/>
                N.W.: 24.3 LBs<br/>
                G.W.: 26.7 LBs
                </p>
            </div>
        </div>
      </div>

      {/* PDF Download */}
      <div className="pdf-download">
        <a
            href="/aya_website_data/resellers_pics/bamboo_kit_2/bamboo_kit_2_info.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pdf-link"
        >
            <img
            src="/aya_website_data/adobe_acrobat_logo.avif"
            alt="PDF Symbol"
            className="pdf-icon"
            />
            <span>Download Info Sheet</span>
        </a>
      </div>
    </div>
  );
};

export default BambooKit2Resellers;