import React, { useState } from 'react';
import './BambooKit1Resellers.css';

const BambooKit1Resellers = () => {
  // List of image paths (1 through 8)
  const images = [
    '/aya_website_data/resellers_pics/bamboo_kit_1/bamboo_kit_1_1.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_1/bamboo_kit_1_2.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_1/bamboo_kit_1_3.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_1/bamboo_kit_1_4.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_1/bamboo_kit_1_5.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_1/bamboo_kit_1_6.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_1/bamboo_kit_1_7.jpg',
    '/aya_website_data/resellers_pics/bamboo_kit_1/bamboo_kit_1_8.jpg',
  ];

  // State for the lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const showPrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const showNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="bamboo-kit-1-resellers">
      {/* Title Section */}
      <h1 className="product-title">BAMBOO KIT 1</h1>

      {/* Top image grid */}
      <div className="image-grid">
        {images.map((imgPath, i) => (
          <div key={i} className="image-card" onClick={() => openLightbox(i)}>
            <img src={imgPath} alt={`Bamboo Kit 1 - ${i + 1}`} />
            <div className="image-overlay"></div> {/* Just an empty div for grey effect */}
          </div>
        ))}
      </div>

      {/* Lightbox / Fullscreen overlay */}
      {isLightboxOpen && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <button className="close-button" onClick={closeLightbox}>×</button>
          <button className="arrow-button left-arrow" onClick={showPrev}>‹</button>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <img src={images[currentIndex]} alt="Fullscreen Bamboo Kit" className="fullscreen-img" />
          </div>
          <button className="arrow-button right-arrow" onClick={showNext}>›</button>
        </div>
      )}

      {/* Bottom section with two cards */}
      <div className="bottom-section">
        <div className="feature-card">
          <h2>PRODUCT FEATURES</h2>
          <ul>
            <li>Includes state-of-the-art AI assistant and exclusive step-by-step video tutorials by a professional sushi chef.</li>
            <li>Make sushi at home with your favorite ingredients, saving a trip to the restaurant.</li>
            <li>Professional sushi knife included.</li>
            <li>11-piece set includes 2 bamboo mats, rice spreader, spatula, sushi knife, 5 pairs of chopsticks, and a storage bag.</li>
            <li>The AYA Bamboo Kit 1 includes a stylish storage bag for the perfect gift.</li>
          </ul>
        </div>

        <div className="feature-card">
            <h2>SHIPPING INFORMATION</h2>
            <div className="shipping-info">
                <p>
                <strong>SKU:</strong><br/>
                SKU <strong>USA</strong>: XD-K4S7-ULI0<br/>
                SKU <strong>UK/EU</strong>: 64-JEXG-HJW8<br/>
                <br/>  {/* Creates an extra blank line */}
                <strong>CTN dimensions and weight:</strong><br/>
                <br/>
                20 sets per CTN<br/>
                <br/>
                MEAS in cm: 47.5 x 28.5 x 31<br/>
                MEAS in inches: 18.7 x 11.2 x 12<br/>
                <br/>
                N.W.: 9.2 KGs<br/>
                G.W.: 10.2 KGs<br/>
                <br/>
                N.W: 20.3 LBs<br/>
                G.W: 22.5 LBs
                </p>
            </div>
        </div>
      </div>

      {/* PDF Download */}
      <div className="pdf-download">
        <a
            href="/aya_website_data/resellers_pics/bamboo_kit_1/bamboo_kit_1_info.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pdf-link"
        >
            <img
            src="/aya_website_data/adobe_acrobat_logo.avif"
            alt="PDF Symbol"
            className="pdf-icon"
            />
            <span>Download Info Sheet</span>
        </a>
      </div>
    </div>
  );
};

export default BambooKit1Resellers;