import React, { useState } from 'react';
import './LoverSetResellers.css';

const LoverSetResellers = () => {
  // List of image paths (1 through 8)
  const images = [
    '/aya_website_data/resellers_pics/lover_set/lover_set_1.jpg',
    '/aya_website_data/resellers_pics/lover_set/lover_set_2.jpg',
    '/aya_website_data/resellers_pics/lover_set/lover_set_3.jpg',
    '/aya_website_data/resellers_pics/lover_set/lover_set_4.jpg',
    '/aya_website_data/resellers_pics/lover_set/lover_set_5.jpg',
    '/aya_website_data/resellers_pics/lover_set/lover_set_6.jpg',
    '/aya_website_data/resellers_pics/lover_set/lover_set_7.jpg',
    '/aya_website_data/resellers_pics/lover_set/lover_set_8.jpg',
  ];

  // State for the lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const showPrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const showNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="lover-set-resellers">
      {/* Title Section */}
      <h1 className="product-title">LOVER SET</h1>

      {/* Top image grid */}
      <div className="image-grid">
        {images.map((imgPath, i) => (
          <div key={i} className="image-card" onClick={() => openLightbox(i)}>
            <img src={imgPath} alt={`Lover Kit - ${i + 1}`} />
            <div className="image-overlay"></div> {/* Just an empty div for grey effect */}
          </div>
        ))}
      </div>

      {/* Lightbox / Fullscreen overlay */}
      {isLightboxOpen && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <button className="close-button" onClick={closeLightbox}>×</button>
          <button className="arrow-button left-arrow" onClick={showPrev}>‹</button>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <img src={images[currentIndex]} alt="Fullscreen Lover Set" className="fullscreen-img" />
          </div>
          <button className="arrow-button right-arrow" onClick={showNext}>›</button>
        </div>
      )}

      {/* Bottom section with two cards */}
      <div className="bottom-section">
        <div className="feature-card">
          <h2>PRODUCT FEATURES</h2>
          <ul>
            <li>Includes state-of-the-art AI assistant and exclusive step-by-step video tutorials by a professional sushi chef.</li>
            <li>3 AYA sushi making tools: 1 natural premium bamboo mat, 1 heart-shaped sushi maker and 1 nigiri maker.</li>
            <li>This is perfect gift and sushi serving set: enjoy a stylish and authentic sushi experience with AYA sushi plates, sauce dishes, bamboo chopsticks and chopstick holders.</li>
            <li>Make mouth-watering sushi in a dash and save your loved ones a trip to the restaurant.</li>
            <li>All items are dishwasher safe, except the bamboo mat and chopsticks, which must be hand-washed. Made of durable material, with lifetime warranty and premium customer service.</li>
          </ul>
        </div>

        <div className="feature-card">
            <h2>SHIPPING INFORMATION</h2>
            <div className="shipping-info">
                <p>
                <strong>SKU: </strong>SushiLoverSet<br/>                
                <br/>  {/* Creates an extra blank line */}
                <strong>CTN dimensions and weight:</strong><br/>
                <br/>
                6 sets per CTN<br/>
                <br/>
                MEAS in cm: 60 x 37 x 40<br/>
                MEAS in inches: 23.6 x 14.6 x 15.7<br/>
                <br/>
                N.W.: 12 KGs<br/>
                G.W.: 13.5 KGs<br/>
                <br/>
                N.W.: 26.5 LBs<br/>
                G.W.: 29.8 LBs
                </p>
            </div>
        </div>
      </div>

      {/* PDF Download */}
      <div className="pdf-download">
        <a
            href="/aya_website_data/resellers_pics/lover_set/lover_set_info.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="pdf-link"
        >
            <img
            src="/aya_website_data/adobe_acrobat_logo.avif"
            alt="PDF Symbol"
            className="pdf-icon"
            />
            <span>Download Info Sheet</span>
        </a>
      </div>
    </div>
  );
};

export default LoverSetResellers;