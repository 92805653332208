import { React, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProductPage from "./pages/ProductPage";
import SupportPage from "./pages/SupportPage";
import ResellersPage from "./pages/ResellersPage";
import SushiMakerTerritory from "./components/SushiMakerTerritory.jsx";
import LoverSetTerritory from "./components/LoverSetTerritory.jsx";
import BambooKit2Territory from "./components/BambooKit2Territory.jsx";
import EcoKitTerritory from "./components/EcoKitTerritory.jsx";
import ChooseProductPage from "./pages/ChooseProductPage.jsx";
import ChatbotPage from "./pages/ChatbotPage.jsx";
import ChopsticksTerritory from "./components/ChopsticksTerritory.jsx";
import ChopsticksPageEn from "./pages/ChopsticksPageEn.jsx";
import ChopsticksPageFr from "./pages/ChopsticksPageFr.jsx";
import ChopsticksPageDe from "./pages/ChopsticksPageDe.jsx";
import ChopsticksPageEs from "./pages/ChopsticksPageEs.jsx";
import ChopsticksPageIt from "./pages/ChopsticksPageIt.jsx";
import BambooKit1Resellers from "./pages/resellers_pages/BambooKit1Resellers.jsx";
import BambooKit2Resellers from "./pages/resellers_pages/BambooKit2Resellers.jsx";
import EcoKitResellers from "./pages/resellers_pages/EcoKitResellers.jsx";
import BazookaKit3Resellers from "./pages/resellers_pages/BazookaKit3Resellers.jsx";
import SushiMaker3Resellers from "./pages/resellers_pages/SushiMaker3Resellers.jsx";
import LoverSetResellers from "./pages/resellers_pages/LoverSetResellers.jsx";


function App() {
  useEffect(() => {
    // Check if a QR code source is provided in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const qrSource = urlParams.get('qrSource');
    console.log("qrSource from URL:", qrSource); // Debugging log

    if (qrSource) {
        // Store the QR code source in sessionStorage
        sessionStorage.setItem('qrCodeSource', qrSource);
        console.log("qrCodeSource stored in sessionStorage:", qrSource); // Debugging log

        // Clear the query parameter from the URL
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, '', newUrl);

        // Scroll to "Choose your product" section
        const productSection = document.getElementById('choose-your-product');
        if (productSection) {
            productSection.scrollIntoView({ behavior: 'smooth' });
        }
    }
}, []);

  return (
    <Router>
      <Header />
      <Routes>
        {/* Homepage Route */}
        <Route path="/" element={<HomePage />} />

        {/* Privacy Policy Route */}
        <Route path="/choose-product" element={<ChooseProductPage />} />

        {/* Privacy Policy Route */}
        <Route path="/aya-assistant" element={<ChatbotPage />} />

        {/* Privacy Policy Route */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Support Page Route */}
        <Route path="/support" element={<SupportPage />} />

        {/* Resellers Page Route */}
        <Route path="/resellers" element={<ResellersPage />} />

        {/* Territory Selection Route */}
        <Route path="/sushimaker-territory" element={<SushiMakerTerritory />} />
        <Route path="/loverset-territory" element={<LoverSetTerritory />} />
        <Route path="/bambookit2-territory" element={<BambooKit2Territory />} />
        <Route path="/ecokit-territory" element={<EcoKitTerritory />} />
        <Route path="/chopsticks-territory" element={<ChopsticksTerritory />} />

        {/* Chopsticks Pages Routes */}
        <Route path="/chopsticks-en" element={<ChopsticksPageEn />} />
        <Route path="/chopsticks-fr" element={<ChopsticksPageFr />} />
        <Route path="/chopsticks-de" element={<ChopsticksPageDe />} />
        <Route path="/chopsticks-es" element={<ChopsticksPageEs />} />
        <Route path="/chopsticks-it" element={<ChopsticksPageIt />} />

        {/* Resellers Products Routes */}
        <Route path="/resellers/bambookit-1-resellers" element={<BambooKit1Resellers />} />
        <Route path="/resellers/bambookit-2-resellers" element={<BambooKit2Resellers />} />
        <Route path="/resellers/ecokit-resellers" element={<EcoKitResellers />} />
        <Route path="/resellers/bazooka-kit-3-resellers" element={<BazookaKit3Resellers />} />
        <Route path="/resellers/sushimaker-3-resellers" element={<SushiMaker3Resellers />} />
        <Route path="/resellers/loverset-resellers" element={<LoverSetResellers />} />

        {/* Dynamic Products Route */}
        <Route path="/:productId" element={<ProductPage />} />

        
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;